import { useCallback, useEffect } from 'react';

export const useClickOutside = ( domNode, callback ) => {
  const handleClick = useCallback(
    ( event ) => {
      if (( !domNode || !domNode.contains( event.target )) && typeof callback === 'function' ) {
        callback( event );
      }
    },
    [ domNode, callback ],
  );

  useEffect(() => {
    document.addEventListener( 'click', handleClick, true );

    return () => {
      document.removeEventListener( 'click', handleClick, true );
    };
  }, [ handleClick ]);
};
